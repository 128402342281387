import React from "react"
import CompanyCard from "./companyCard"

export default function CompaniesList({ companies }) {
  return (
    <div className="my-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {companies.map(company => {
        return <CompanyCard key={company.id} company={company} />
      })}
    </div>
  )
}
