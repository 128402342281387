import React from "react"
import { Link } from "gatsby"

const BreadCrumbs = ({ breadcrumbs }) => {
  return (
    <ul
      className="flex flex-wrap text-gray-500 text-sm leading-6 lg:text-base py-6"
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
    >
      {breadcrumbs &&
        breadcrumbs.map(crumb =>
          !crumb.isLast ? (
            <li
              key={crumb.position}
              className="inline-flex items-center"
              itemProp="itemListElement"
              itemScope={true}
              itemType="https://schema.org/ListItem"
            >
              <Link
                className="underline hover:text-yellow-900"
                to={crumb.url}
                itemScope={true}
                itemType="http://schema.org/Thing"
                itemProp="item"
                itemID={crumb.url}
              >
                <span itemProp="name">{crumb.name}</span>
              </Link>
              <span className="mx-2 text-gray-400">\</span>
              <meta itemProp="position" content={crumb.position} />
            </li>
          ) : (
            <li
              key={crumb.position}
              className="inline-flex items-center"
              itemProp="itemListElement"
              itemScope={true}
              itemType="https://schema.org/ListItem"
            >
              <span itemProp="name">{crumb.name}</span>
              <meta itemProp="position" content={crumb.position} />
            </li>
          )
        )}
    </ul>
  )
}

export default BreadCrumbs
