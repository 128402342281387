import React from "react"
import { graphql, Link } from "gatsby"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"

import Layout from "../components/layout"
import Seo from "../components/global/seo"
import BreadCrumbs from "../components/global/BreadCrumbs"
import CompaniesList from "../components/catalog/catalogList"
import FilterCard from "../components/catalog/filterCard"

export default class UkraineList extends React.Component {
  render() {
    const companies = this.props.data.allTahoservCsv.nodes
    const fullCompaniesList = this.props.data.allCount.nodes
    const regions = this.props.data.allMapOblCsv.nodes
    const { currentPage, totalPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === totalPages
    const prevPage =
      currentPage - 1 === 1 ? "/kompaniyi/" : `/kompaniyi/${currentPage - 1}/`
    const nextPage = `/kompaniyi/${currentPage + 1}/`

    const companyBreadCrumbs = [
      { position: 1, name: "Тахографи", url: "/", isLast: false },
      { position: 2, name: "Компанії", url: "/kompaniyi/", isLast: true },
    ]

    return (
      <Layout>
        <Seo
          title={
            currentPage === 1
              ? `Тахографи в Україні`
              : `Тахографи в Україні, сторінка ${currentPage}`
          }
          description={
            currentPage === 1
              ? `Знайти сертифиіковану в Україні компанію з продажу, обслуговання і ремонту тахографів в Україні на порталі Taho.org.ua.`
              : `Знайти сертифиіковану в Україні компанію з продажу, обслуговання і ремонту тахографів в Україні на порталі Taho.org.ua. Сторінка ${currentPage}.`
          }
          noindex={currentPage === 1 ? false : true}
          canonical="/kompaniyi/"
        />
        <main className="max-w-7xl mx-auto px-4 sm:px-6">
          <BreadCrumbs breadcrumbs={companyBreadCrumbs} />

          <h1 className="text-4xl font-bold mt-6 mb-4">
            Тахографи в компаніях України
            {!isFirst && `, сторінка ${currentPage}`}
          </h1>
          {isFirst && (
            <div className="py-4">
              Знайдено {fullCompaniesList.length} компаній в Україні,
              сертифікованих Укртрансбезпекою для обслуговання тахографів.
            </div>
          )}

          <div className="py-4">
            <div>Обрати область:</div>
            {regions.map(obl => {
              let oblCompaniesCount = fullCompaniesList.filter(
                o => o.mapObl === obl.oblName
              ).length
              return (
                <FilterCard
                  key={obl.id}
                  city={``}
                  oblast={obl.oblName}
                  oblSlug={obl.slug}
                  count={oblCompaniesCount}
                />
                // <Link
                //   key={obl.id}
                //   to={`/kompaniyi/${slugify(obl.oblName)}/`}
                //   className="hover:underline my-2 mr-2 whitespace-nowrap inline-block text-gray-600"
                // >
                //   {obl.oblName}{" "}
                //   <span className="font-extralight">{oblCompaniesCount}</span>
                // </Link>
              )
            })}
          </div>
          <CompaniesList companies={companies} />
        </main>

        <div className="bg-white my-6 max-w-7xl mx-auto px-4 sm:px-6 py-3 flex items-center justify-between border-t border-gray-200">
          <div className="flex-1 flex justify-between sm:hidden">
            {!isFirst && (
              <Link
                to={prevPage}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Попередня
              </Link>
            )}
            {!isLast && (
              <Link
                to={nextPage}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Наступна
              </Link>
            )}
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Сторінка <span className="font-medium">{currentPage}</span> з{" "}
                <span className="font-medium">{totalPages}</span>
              </p>
            </div>
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                {!isFirst && (
                  <Link
                    to={prevPage}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Попередня</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </Link>
                )}

                {Array.from({ length: totalPages }, (_, i) =>
                  currentPage === i + 1 ? (
                    <span
                      key={`pagination-number${i + 1}`}
                      // to={i === 0 ? "/kompaniyi/" : `/kompaniyi/${i + 1}/`}
                      className="z-10 bg-yellow-50 border-yellow-500 text-yellow-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    >
                      {i + 1}
                    </span>
                  ) : (
                    <Link
                      key={`pagination-number${i + 1}`}
                      to={i === 0 ? "/kompaniyi/" : `/kompaniyi/${i + 1}/`}
                      className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                      aria-current="page"
                    >
                      {i + 1}
                    </Link>
                  )
                )}

                {/* <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  ...
                </span> */}
                {!isLast && (
                  <Link
                    to={nextPage}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span className="sr-only">Наступна</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </Link>
                )}
              </nav>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export const companiesListQuery = graphql`
  query companiesListQuery($skip: Int!, $limit: Int!) {
    allTahoservCsv(
      sort: { fields: sortID, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        slug
        idNumber
        title
        shortname
        companyType
        address
        mapCity
        mapObl
        verifServ
        digitServ
        analogServ
      }
    }
    allMapOblCsv {
      nodes {
        id
        oblName
        oblNameChanged
        slug
      }
    }
    allCount: allTahoservCsv {
      nodes {
        mapCity
        mapObl
        id
      }
    }
  }
`
