import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
// import { slugify } from "../../utils/transcriptSlug"

export default function FilterCard({ city, oblast, oblSlug, citySlug, count }) {
  return (
    <Link
      to={!city ? `/kompaniyi${oblSlug}` : `/kompaniyi${citySlug}`}
      className="hover:underline my-2 mr-3 whitespace-nowrap inline-block"
    >
      {!city ? (
        <span className="border border-yellow-600 rounded-xl px-2 py-1">
          {oblast} <span className="text-yellow-600">{count}</span>
        </span>
      ) : (
        <span className="border border-yellow-600 rounded-xl px-2 py-1">
          {city} <span className="text-yellow-600">{count}</span>
        </span>
      )}
    </Link>
  )
}

FilterCard.propTypes = {
  city: PropTypes.string,
  oblast: PropTypes.string,
  oblSlug: PropTypes.string,
  count: PropTypes.number,
}

FilterCard.defaultProps = {
  city: null,
  oblast: null,
  oblSlug: null,
  count: null,
}
