import React from "react"
import { Link } from "gatsby"
import { slugify } from "../../utils/transcriptSlugOld"

import { ChipIcon, CogIcon, ScaleIcon } from "@heroicons/react/outline"

const CompanyCard = ({ company }) => {
  const serviceAnalog = company.analogServ === "TRUE" ? true : false
  const serviceDigital = company.digitServ === "TRUE" ? true : false
  const serviceVetification = company.verifServ === "TRUE" ? true : false
  return (
    <div className="p-6 rounded-lg shadow-lg flex flex-col justify-between">
      <div className="text-2xl font-bold text-gray-800 mb-6">
        {`${company.shortname}`}
      </div>
      <p className="text-gray-700 block mb-4">{`${company.title}, ${company.mapCity}, ${company.mapObl} область`}</p>
      <ul className="text-gray-700 block mb-6">
        {serviceAnalog && (
          <li>
            <CogIcon className="text-yellow-600 h-6 w-auto inline-block mr-2" />
            аналогові тахографи
          </li>
        )}
        {serviceDigital && (
          <li>
            <ChipIcon className="text-yellow-600 h-6 w-auto inline-block mr-2" />
            цифрові тахографи
          </li>
        )}
        {serviceVetification && (
          <li>
            <ScaleIcon className="text-yellow-600 h-6 w-auto inline-block mr-2" />
            повірка тахографів
          </li>
        )}
      </ul>
      <Link
        className="w-52 text-center p-2 pl-5 pr-5 bg-transparent border-2 border-yellow-600 text-yellow-600 text-lg rounded-lg transition-colors duration-700 transform hover:bg-yellow-700 hover:text-gray-100 focus:border-4 focus:border-yellow-700"
        to={`/${company.slug}-${slugify(company.shortname)}/`}
      >
        Контакти компанії
      </Link>
    </div>
  )
}

export default CompanyCard
